import store from '@/store';

export class ResponseError extends Error {
  constructor(message) {
    super(message);
  }

  default() {
    store.dispatch('notification/addError', this.message);
  }
}