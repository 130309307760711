import { logout } from '@/helpers/account';
import { ResponseError } from '@/helpers/error';
import store from '@/store';

async function handleErrorResponse(response, authorization) {
  let responseText;

  switch((response.headers.get('content-type') || '').split(';')[0]) {
    case 'application/json':
    case 'application/problem+json': {
      const responseData = await response.json();
      responseText = responseData.detail;
      break;
    }

    default: {
      responseText = await response.text();
      break;
    }
  }
  
  
  const errorText = responseText || `
    Er is een onverwachte fout opgetreden.
    Probeer het later nogmaals of neem contact op als het probleem aanhoudt.
  `;

  if (
    store.getters['account/authorizationIsExpired']
    && authorization
  ) {
    logout();
  }

  throw new ResponseError(errorText);
}

function handleSuccessResponse(response) {
  if (response.status === 204) {
    return;
  }
  
  const contentType = response.headers.get('content-type');

  if (
    contentType
    && contentType.includes('application/json')
  ) {
    return response.json();
  }

  return response.text();
}

function request(method, path, data, authorization) {
  return fetch(`${process.env.VUE_APP_API_BASE_URL}${path}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(authorization && {
        Authorization: `Bearer ${store.getters['account/bearerToken']}`,
        'X-Organization-Code': store.getters['account/organizationCode'],
      }),
    },
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (!response.ok) {
        await handleErrorResponse(response, authorization);
      }

      return handleSuccessResponse(response);
    });
}

export const deleteRequest = (path, data = undefined, authorization = true) => request('DELETE', path, data, authorization);
export const getRequest = (path, authorization = true) => request('GET', path, undefined, authorization);
export const postRequest = (path, data = undefined, authorization = true) => request('POST', path, data, authorization);
export const putRequest = (path, data = undefined, authorization = true) => request('PUT', path, data, authorization);
export const patchRequest = (path, data = undefined, authorization = true) => request('PATCH', path, data, authorization);