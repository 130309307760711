export default {
  state: () => ({
    modules: [],
  }),

  getters: {
    sortedModuleIds: (_, getters) => getters.sortedModules
      .map((sortedModule) => sortedModule.id),
    sortedModules: (state) => state.modules
      .sort((a, b) => a.order - b.order),
  },

  actions: {
    setModules({ commit }, modules) {
      commit('setModules', modules || []);
    },
  },

  mutations: {
    setModules(state, modules) {
      state.modules = modules;
    }
  },

  namespaced: true,
};
