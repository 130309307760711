export default {
  state: {
    actions: [],
    routeDisplayName: null
  },

  getters: {
    routeDisplayName(state) {
      return state.routeDisplayName;
    }
  },

  actions: {
    addAction({ commit }, payload) {
      commit('addAction', payload);
    },

    clearActions({ commit }) {
      commit('clearActions');
    },

    setRouteDisplayName({ commit }, payload) {
      commit('setRouteDisplayName', payload);
    },

    clearRouteDisplayName({ commit}) {
      commit('clearRouteDisplayName');
    }
  },

  mutations: {
    addAction(state, payload) {
      state.actions.push(payload);
    },

    clearActions(state) {
      state.actions = [];
    },

    setRouteDisplayName(state, payload) {
      state.routeDisplayName = payload;
    },

    clearRouteDisplayName(state) {
      state.routeDisplayName = null;
    }
  },

  namespaced: true,
};
