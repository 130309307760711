export default {
	state: {
    modules: JSON.parse(localStorage.getItem('modules')) || null
	},

	getters: {
    hasModules(state) {
      return state.modules != null;
    },

		appModules(state) {
      return state.modules;
    },

    appModuleName: (state) => (moduleName) =>
      state.modules.find(m => m.name === moduleName)?.displayName ?? ""
  },

  actions: {
    getAppModuleName(state, moduleName) {
      return state.getModule(moduleName).displayName;
    },

    setAppModules({ commit }, payload) {
      commit('mutateModules', payload);
      localStorage.setItem('modules', JSON.stringify(payload));
    },
  },

  mutations: {
    mutateModules(state, payload) {
      state.modules = payload;
    }
  },

  namespaced: true
}