const ModuleList = [
    {
      icon: 'event',
      link: '/events',
      name: 'Events',
      translatedName: 'Evenementen',
    },
    {
      icon: 'newspaper',
      link: '/news',
      name: 'News',
      translatedName: 'Nieuws',
    },
    {
      icon: 'hive',
      link: '/projects',
      name: 'Projects',
      translatedName: 'Projecten',
    },
    {
      icon: 'apartment',
      link: '/company',
      name: 'CompanyPage',
      translatedName: 'Bedrijven'
    },
    {
      icon: 'groups',
      link: '/admins',
      name: 'Admins',
      translatedName: 'Admins',
    },
    {
      icon: 'lock_person',
      link: '/roles',
      name: 'roles',
      translatedName: 'Rollen & Rechten',
    },
    {
      icon: 'help',
      link: '/faq',
      name: 'Faq',
      translatedName: 'Veelgestelde vragen'
    },
    {
      icon: 'folder',
      link: '/file-browser',
      name: 'Documents',
      translatedName: 'Documentbeheer'
    },
    {
      icon: 'poll',
      link: '/polls/',
      name: 'Poll',
      translatedName: 'Peilingen'
    }
];

export default ModuleList;